import * as React from "react";
import { graphql } from "gatsby";
import classNames from "classnames";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";

import Layout from "../../components/layout";
import Button from "../../components/ui/button";
import Title from "../../components/ui/title";
import Section from "../../components/layout/section";
import TwoContainersLayout from "../../components/layout/twoContainersLayout";
import Instinct from "../../assets/svg/instinct.svg";
import Reflex from "../../assets/svg/reflex.svg";
import Brain from "../../assets/svg/brain.svg";

import * as styles from "./types.module.scss";
import { StaticImage } from "gatsby-plugin-image";
import { SEOv2 } from "../../components/seov2";

const bodyClasses = classNames(styles.text, styles.markdown);

const Types = ({ data }) => (
  <Layout>
    <Section type="lightest-green" className={styles.firstSectionContainer}>
      <TwoContainersLayout>
        <div className={styles.left}>
          <span className={styles.heading}>
            Видове автоматизации тип „Умен дом“
          </span>
        </div>
        <div className={styles.right}>
          <StaticImage
            alt="types of smart homes hero image"
            className={styles.heroImage}
            objectFit="contain"
            src="../../assets/images/smart-home.png"
            placeholder="blurred"
          />
        </div>
      </TwoContainersLayout>
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <div className={styles.grid}>
        <Instinct className={styles.smallImage} />
        <Title className={styles.articleTitle}>
          {data.allStrapiTypesOfAutomation.edges[0].node.title}
        </Title>
        <ReactMarkdown className={bodyClasses}>
          {data.allStrapiTypesOfAutomation.edges[0].node.text.data.text}
        </ReactMarkdown>
      </div>
      <div className={styles.grid} style={{ marginTop: "98px" }}>
        <Reflex className={styles.smallImage} />
        <Title className={styles.articleTitle}>
          {data.allStrapiTypesOfAutomation.edges[1].node.title}
        </Title>
        <ReactMarkdown className={bodyClasses}>
          {data.allStrapiTypesOfAutomation.edges[1].node.text.data.text}
        </ReactMarkdown>
      </div>
      <div className={styles.grid} style={{ marginTop: "98px" }}>
        <Brain className={styles.smallImage} />
        <Title className={styles.articleTitle}>
          {data.allStrapiTypesOfAutomation.edges[2].node.title}
        </Title>
        <ReactMarkdown className={bodyClasses}>
          {data.allStrapiTypesOfAutomation.edges[2].node.text.data.text}
        </ReactMarkdown>
      </div>
    </Section>
    <Section type="nearly-black" className={styles.sectionReadMore}>
      <span className={styles.subtitle}>Концепцията “Умен Дом”</span>
      <Title className={styles.title} inverted={true}>
        Какво е “IoT” и за какво служи?
      </Title>
      <Button
        className={styles.button}
        title={"Прочети"}
        inverted
        to="/smart-home/iot"
      />
    </Section>
    <Section type="white" className={styles.sectionContainer}>
      <Title className={styles.mobileTitle}>Свържете се с нас</Title>
      <Button
        className={styles.button}
        title={"Свържете се с нас"}
        to="/contacts"
      />
    </Section>
  </Layout>
);

export const query = graphql`
  query {
    allStrapiTypesOfAutomation(sort: { fields: createdAt }) {
      edges {
        node {
          title
          text {
            data {
              text
            }
          }
        }
      }
    }
  }
`;

export default Types;

export const Head = () => (
  <SEOv2 title="Видове автоматизации" previewImg="types" />
);
