// extracted by mini-css-extract-plugin
export var articleTitle = "types-module--articleTitle--40f93";
export var button = "types-module--button--4b994";
export var firstSectionContainer = "types-module--firstSectionContainer--7cba3";
export var grid = "types-module--grid--e4219";
export var heading = "types-module--heading--39a0d";
export var heroImage = "types-module--heroImage--de84b";
export var left = "types-module--left--63ed9";
export var markdown = "types-module--markdown--ac9ca";
export var mobileTitle = "types-module--mobileTitle--2556a";
export var right = "types-module--right--31faa";
export var sectionContainer = "types-module--sectionContainer--a10e8";
export var sectionReadMore = "types-module--sectionReadMore--4c2cc";
export var smallImage = "types-module--smallImage--6b28c";
export var subtitle = "types-module--subtitle--aea1d";
export var text = "types-module--text--79784";
export var title = "types-module--title--44dc3";